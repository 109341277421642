import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFilter } from '../../entities/filter';
import { FilmProvider } from '../../providers/film.provider';
import { IProvider } from '../../interfaces/provider.interface';
import { ISectionDto } from '../../dtos/section-dto';
import { FilmService } from '../../services/film.service';
import { AwardService } from "../../services/award.service";
import { CinematographerProvider } from "../../providers/cinematographer.provider";
import { CinematographerService } from "../../services/cinematographer.service";
import { MatTabChangeEvent } from "@angular/material/tabs";

export interface IListFilmTabsConfiguration {
  sections: ISectionDto[];
  parameters: IFilter[] | null;
  isAdmin: boolean;
  inkBarColorClass: string | null;
  selectedTabIndex: number | null;
}

@Component({
  selector: 'fspro-list-film-tabs',
  templateUrl: './list-film-tabs.component.html',
  styleUrls: ['./list-film-tabs.component.scss']
})
export class ListFilmTabsComponent implements OnInit {

  public orderedAwards = [];
  public _listFilmTabConfiguration: IListFilmTabsConfiguration | null = null;
  private defaultProviderLoaded: boolean = false;

  @Input() public awards = [];

  @Input() public set listFilmTabConfiguration(value: IListFilmTabsConfiguration) {
    this._listFilmTabConfiguration = value;
    this.setDefaultProvider();
  }
  @Output() tabChanged = new EventEmitter<number>();

  public provider$ = new BehaviorSubject<IProvider | null>(null);

  constructor(
    private filmService: FilmService,
    private awardService: AwardService,
    private cinematographerService: CinematographerService
  ) {}

  ngOnInit(): void {}

  private getFilmProvider(listFilmIdentifier: string): IProvider {
    const filterListFilm = this.getFilterListFilm(listFilmIdentifier);
    let filters = Object.assign([], this._listFilmTabConfiguration.parameters);
    filters.push(filterListFilm);
    return new FilmProvider(this.filmService, filters);
  }

  private getDirectorProvider(sectionId: number, cinematographers: { id: number, director: boolean }[]): IProvider {
    const directorFilter = ListFilmTabsComponent.getFilterCinematographer(cinematographers);
    const filterListFilm = this.getFilterSectionId(sectionId);
    return new CinematographerProvider(this.cinematographerService, [directorFilter, filterListFilm]);
  }

  private static getFilterCinematographer(cinematographers: { id: number, director: boolean }[]): IFilter {
    return {
      field: 'id',
      value: cinematographers.map(elm => `${elm.id}:${elm.director}`).join(','),
      type: 0
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.orderedAwards = [];
    const selectedTab = event.index;
    this.loadNewProvider(this._listFilmTabConfiguration.sections[selectedTab]);
    this.tabChanged.emit(event.index);
  }

  loadNewProvider(section: ISectionDto): void {
    if (section.directorSection) {
      this.provider$.next(this.getDirectorProvider(section.id, section.directors));
    } else {
      this.provider$.next(this.getFilmProvider(section.listFilmIdentifier));
    }
    this.getOrderedAwards(section.listFilmIdentifier);
  }

  getOrderedAwards(sectionIdentifier: string) {
    this.awardService.getOrderedAwardsForSection(sectionIdentifier).toPromise().then((awards) => {
      this.orderedAwards = awards;
    })
  }

  getFilterListFilm(listFilmIdentifier: string): IFilter {
    return {
      field: 'listsFilms',
      value: listFilmIdentifier,
      type: 0
    }
  }

  getFilterSectionId(sectionId: number): IFilter {
    return {
      field: 'sectionId',
      value: sectionId.toString(),
      type: 0
    }
  }

  /**
   * This function will create the default provider that will be passed to the film grid.
   */
  private setDefaultProvider(): void {
    // We want to load the default provider only once
    if (this.defaultProviderLoaded) {
      return;
    }

    // For obvious reason we need to wait that the list film tab configuration is set
    if (!this._listFilmTabConfiguration) {
      return;
    }

    if (this._listFilmTabConfiguration.sections && this._listFilmTabConfiguration.selectedTabIndex !== null
      && this._listFilmTabConfiguration.selectedTabIndex >= 0
      && this._listFilmTabConfiguration.selectedTabIndex <= (this._listFilmTabConfiguration.sections.length -1)
    ) {
      this.defaultProviderLoaded = true;
      this.loadNewProvider(this._listFilmTabConfiguration.sections[this._listFilmTabConfiguration.selectedTabIndex]);
    } else {
      this._listFilmTabConfiguration.selectedTabIndex = 0;
        this.defaultProviderLoaded = true;
        this.loadNewProvider(this._listFilmTabConfiguration.sections[0]);
    }
  }
}
