import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';
import { IRumoInteractionDto, RumoInteraction } from "../dtos/rumo-interaction-dto";

@Injectable()
export class RumoService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/rumo';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns Director data
   * @param userId
   * @param filmId
   * @param type
   */
  addInteraction(userId: number, filmId: number, type: RumoInteraction): Observable<{success: boolean}> {
    const interaction = {
      movie_id: filmId.toString(),
      user_id: userId.toString(),
      interaction_type: type
    } as IRumoInteractionDto;

    const fullUrl = `${this.urlBase}/user-interaction`;
    return this.http.post<{success: boolean}>(fullUrl, interaction);
  }
}
