<div class="director-card" [ngClass]="{ 'dark': theme === 'DarkMode'}">
  <div class="picture-container">
    <a [routerLink]="['/', cinematographer.type.code === 'director' ? 'director' : 'talent', cinematographer.slug]">
      <img [appCacheImage]="cinematographer.pictureList" alt="{{ cinematographer.firstName }} {{ cinematographer.lastName }}" #picture>
    </a>
  </div>
  <div class="details-container">
    <a class="name" [routerLink]="['/', cinematographer.type.code === 'director' ? 'director' : 'talent', cinematographer.slug]">
      <h2 #nameEl>{{ cinematographer.firstName}} {{ cinematographer.lastName }}</h2>
    </a>
    <ul class="countries" #countriesEl>
      <li *ngFor="let country of cinematographer.countries">{{ country }}</li>
    </ul>
    <ul class="countries" *ngIf="showType && cinematographer.type">
      <li>{{ cinematographer.type.name }}</li>
    </ul>
    <ul class="countries" *ngIf="showType && !cinematographer.type">
      <li>Director</li>
    </ul>
    <ul class="films" #filmsEl>
      <li *ngFor="let film of cinematographer.films">
        <a [routerLink]="film.filmPageUrl">
          {{ film.internationalTitle }}
        </a>
      </li>
    </ul>
    <p class="link-more-container">
      <a class="link-more" *ngIf="isMoreEnable" [routerLink]="['/', cinematographer.type.code === 'director' ? 'director' : 'talent', cinematographer.slug]">
          {{ 'fspro.front.labelpage.directorslist__list.label.more' | translate }}...
      </a>
    </p>
  </div>
</div>
