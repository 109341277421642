import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { FSProService } from 'src/app/shared/services/fspro-service';
import { parameters } from 'src/app/shared/parameters';
import { ISort } from 'src/app/shared/entities/sort';
import { IFilter } from 'src/app/shared/entities/filter';
import { ICountry } from 'src/app/shared/entities/country';
import { IEmailMessageRequest, IEmailMessageResponse } from '../dtos/email-message.dto';
import { IFilmDetailsDto } from '../dtos/film-details-dto';
import { IFilmDto, IFilmHydratedSearchDto, InvitationLinkDto } from '../dtos/film-dto';
import { ISectionDto } from '../dtos/section-dto';
import {AuthenticationService} from "../../authentications/authentication.service";
import { RumoInteraction } from "../dtos/rumo-interaction-dto";
import { RumoService } from "./rumo.service";

@Injectable()
export class FilmService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/films';

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private rumoService: RumoService
  ) {
    super();
  }

  /**
   * Use this service the get a list of films in a search context. When you need the minimum details.
   */
  getFilmList(start = 0, limit = 0, sort: ISort = null, search = null, filters: IFilter[] = null): Observable<IFilmDto[]> {

    const fullUrl = this.buildListUrl(this.urlBase, start, limit, sort, search, filters);

    return this.http.get<IFilmDto[]>(fullUrl).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          this.addFilmPageUrl(obj);
          return obj;
        });
        return data;
      }));
  }

  /**
   * Use this service to retrieve a list of film with all the details including rights.
   * @param start
   * @param limit
   * @param sort
   * @param search
   * @param filters
   */
  getFilms(start: number, limit: number, sort: ISort | ISort[] = null, search = null, filters: IFilter[] = null): Observable<IFilmDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/hydrated/', start, limit, sort, search, filters);
    return this.http.get<IFilmDto[]>(fullUrl).pipe(
      map((elements) => {
        elements.map((element) => {
          this.addFilmPageUrl(element);
        });
        return elements;
      })
    );
  }

  searchFilms(start: number, limit: number, sort: ISort | ISort[] = null, searchValue = null, filters: IFilter[] = null): Observable<IFilmDto[]> {
    let fullUrl = this.buildListUrl(this.urlBase + '/hydrated/', start, limit, sort, null, filters);
    let params = new HttpParams();
    params = params.set('searchValue', searchValue);
    params = params.set('includeExpired', 'true');
    fullUrl = fullUrl + '&' + params.toString();
    return this.http.get<IFilmDto[]>(fullUrl).pipe(
      map((elements) => {
        elements.map((element) => {
          this.addFilmPageUrl(element);
        });
        return elements;
      })
    );
  }

  /**
   * Use this service to retrieve a list of film with all the details including rights.
   * @param start
   * @param limit
   * @param sort
   * @param search
   * @param filters
   * @param body
   */
  getFilmsPost(start: number, limit: number, sort: ISort | ISort[] = null, search = null, filters: IFilter[] = null, body: IFilmHydratedSearchDto): Observable<IFilmDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/hydrated/', start, limit, sort, search, filters);
    return this.http.post<IFilmDto[]>(fullUrl, body).pipe(
      map((elements) => {
        elements.map((element) => {
          this.addFilmPageUrl(element);
        });
        return elements;
      })
    );
  }

  /**
   * Use this service to retrieve all the films linked to a specific list film parent identifier
   */
  getFilmFromListIdentifier(identifier: string): Observable<IFilmDto[]> {
    return this.http.get<IFilmDto[]>(this.urlBase + '/listFilm/' + identifier);
  }

  /**
   * Use this service to retrieve all the films specified by the given id list
   *
   * @param filmIds
   * @returns
   */
  getFilmFromFilmIdList(filmIds: string): Observable<IFilmDto[]> {
    return this.http.get<IFilmDto[]>(this.urlBase + '/home?filmIds=' + filmIds).pipe(
      map((elements) => {
        elements.map((element) => {
          this.addFilmPageUrl(element);
        });
        return elements;
      })
    );
  }

  /**
   * Use this service to retrieve all the recommended films
   */
  getRecommendedFilms(start: number, limit: number): Observable<IFilmDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/recommendations', start, limit, null, null, null);
    return this.http.get<IFilmDto[]>(fullUrl).pipe(
      map((elements) => {
        elements.map((element) => {
          this.addFilmPageUrl(element);
        });
        return elements;
      })
    );
  }

  getRecommendedFilmsByRumo(): Observable<IFilmDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/recommendations-by-rumo', 0, 0, null, null, null);
    return this.http.get<IFilmDto[]>(fullUrl).pipe(
      map((elements) => {
        elements.map((element) => {
          this.addFilmPageUrl(element);
        });
        return elements;
      })
    );
  }

  /**
   * Use this service to remove a film from the recommendation list
   */
  removeRecommendedFilms(id: number): Observable<IFilmDto> {
    return this.http.get<IFilmDto>(this.urlBase + '/recommendations/remove/' + id);
  }

  /**
   * Use this service to add a film to the watch list (use case: Watch later)
   */
  addToWatchList(id: number): Observable<IFilmDto> {
    return this.http.get<IFilmDto>(this.urlBase + '/watch-list/add/' + id).pipe(
      filter((film) => film.availableOnRumo),
      switchMap((film: IFilmDto) =>
        this.rumoService.addInteraction(
          this.authenticationService.user.userId,
          film.id,
          RumoInteraction.ADD_TO_LIST
        ).pipe(
          map(() => film)
        )
      )
    );
  }

  removeFromWatchList(id: number): Observable<IFilmDto> {
    return this.http.get<IFilmDto>(this.urlBase + '/watch-list/remove/' + id);
  }

  searchFilmsWithExclusion(listFilmId: number, searchTerm: string, isProject: boolean): Observable<IFilmDto[]> {
    return this.http.post<IFilmDto[]>(this.urlBase + '/search-with-exclusion/', {
      listFilmId: listFilmId,
      isProject: isProject,
      searchTerm: searchTerm
    });
  }

  getForMainFilmCarousel(): Observable<IFilmDto[]> {
    return this.http.get<IFilmDto[]>(this.urlBase + '/home/main-carousel').pipe(
      map((elements) => {
        elements.map((element) => {
          this.addFilmPageUrl(element);
        });
        return elements;
      })
    );
  }

  getInvitationLink(url: string): Observable<InvitationLinkDto> {
    return this.http.get<InvitationLinkDto>(url);
  }

  getCountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>(this.urlBase + '/countries').pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }

  /**
   * Return previous work related to a film
   * @param start
   * @param limit
   * @param filmId
   * @returns
   */
  getPreviousWork(start: number, limit: number, filmId: string): Observable<IFilmDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + `/${filmId}/previous-work`, start, limit, null, null, null);

    return this.http.get<IFilmDto[]>(fullUrl).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          this.addFilmPageUrl(obj);
          return obj;
        });
        return data;
      }));
  }

  /**
   * Send a message to the company
   * @param body
   * @param filmId
   * @param filmCompanyId
   * @returns
   */
  sendContactFilmCompany(body: IEmailMessageRequest, filmId: number, filmCompanyId: number): Observable<IEmailMessageResponse> {
    const url = `${this.urlBase}/contact/${filmId}/${filmCompanyId}`;

    return this.http.post<IEmailMessageResponse>(url, body);

  }

  /**
   * Send a message to the company
   * @param body
   * @param directorSlug
   * @returns
   */
  sendMessageToDirector(body: IEmailMessageRequest, directorSlug: string): Observable<IEmailMessageResponse> {
    const url = `${this.urlBase}/contact-director/${directorSlug}`;

    return this.http.post<IEmailMessageResponse>(url, body);

  }

  /**
   * Send a message to the talent
   * @param body
   * @param talentSlug
   * @returns
   */
  sendMessageToTalent(body: IEmailMessageRequest, talentSlug: string): Observable<IEmailMessageResponse> {
    const url = `${this.urlBase}/contact-talent/${talentSlug}`;

    return this.http.post<IEmailMessageResponse>(url, body);

  }

  /**
   * Return the details of a film by given slug
   * @param slug slug of a film
   */
  getFilmDetailsBySlug(slug: string): Observable<IFilmDetailsDto> {

    const url = `${this.urlBase}/by-slug/${slug}`;

    return this.http.get<IFilmDetailsDto>(url).pipe(map(data => {
      this.convertDates(data);
      this.addFilmPageUrl(data);
      if (data.directors?.length > 0) {
        data.directors.forEach((director) => {
          if (director.films.length > 0) {
            director.films.forEach(film => this.addFilmPageUrl(film));
          }
        })
      }
      this.convertContacts(data);

      return data;
    }));

  }

  /**
   * Return the Director's Selected Filmography
   * @param id Director's id
   * @param startAt
   * @param limit
   * @returns
   */
  public getDirectorFilms(id: number, startAt: number, limit: number): Observable<IFilmDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/by-director/' + id, startAt, limit, null, null, null);
    return this.http.get<IFilmDto[]>(fullUrl).pipe(map(data => {
      data = data.map((obj) => {
        this.convertDates(obj);
        this.addFilmPageUrl(obj);
        return obj;
      })
      return data;
    }));
  }

  /**
   * Return the talent's selected filmography
   *
   * @param id
   * @param startAt
   * @param limit
   */
  public getTalentFilms(id: number, startAt: number, limit: number): Observable<IFilmDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/by-talent/' + id, startAt, limit, null, null, null);
    return this.http.get<IFilmDto[]>(fullUrl).pipe(map(data => {
      data = data.map((obj) => {
        this.convertDates(obj);
        this.addFilmPageUrl(obj);
        return obj;
      })
      return data;
    }));
  }

  public getFilmForRhlu(): Observable<IFilmDto[]> {
    return this.http.get<IFilmDto[]>(`${this.urlBase}/for-rhlu/`);
  }

  public getFilmCountForRhlu(): Observable<number> {
    return this.http.get<number>(`${this.urlBase}/count/rhlu/`);
  }

  public getExpiringFilms(): Observable<IFilmDto[]> {
    return this.http.get<IFilmDto[]>(`${this.urlBase}/expiring/`);
  }

  /**
   * Modifies the given film and adds the film page URL or autoplay URL.
   * @param film
   */
  public addFilmPageUrl(film: IFilmDto): IFilmDto {
    if (film === null || !('slug' in film) || !film.slug) {
      return film;
    }

    if (film.filmMediaId) {
      film.filmAutoPlayUrl = '/player/' + film.filmMediaId;
    }

    if (film.trailerMediaId) {
      film.trailerUrl = '/player/' + film.trailerMediaId;
    } else {
      film.trailerUrl = null;
    }

    film.filmPageUrl = '/film/' + film.slug;

    return film;
  }

  private convertContacts(film: IFilmDto): IFilmDto {
    const groupedContacts = film.contacts.reduce((result, contact) => {
      const { id, name, email, company } = contact;
      const key = `${company.name}-${company.type}`;
      if (!result[key]) {
        result[key] = {
          id: company.id,
          name: company.name,
          type: company.type,
          infos: [{ id, name, email }]
        };
      } else {
        result[key].infos.push({ id, name, email });
      }
      return result;
    }, {});
    film.contacts = Object.values(groupedContacts);

    return film;
  }

  /**
   * Get my films
   * @param start
   * @param limit
   * @param sort
   * @param search
   * @param filters
   * @returns
   */
  public getRightHolders(start = 0, limit = 0, sort: ISort = null, search = null, filters: IFilter[] = null) {

    const fullUrl = this.buildListUrl(`${this.urlBase}/right-holders`, start, limit, sort, search, filters);

    return this.http.get<IFilmDto[]>(fullUrl).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          this.addFilmPageUrl(obj);
          return obj;
        });
        return data;
      }));
  }

  /**
   *
   * @returns
   */
  public getRightsHoldersSectionsForFilms(): Observable<ISectionDto[]> {
    const url = `${this.urlBase}/right-holders/sections`;
    return this.http.get<ISectionDto[]>(url);
  }

  public getBookingConditions(filmId: number): Observable<{conditions: string}> {
    const url = `${this.urlBase}/${filmId}/booking-conditions`;
    return this.http.get<{conditions: string}>(url, {headers: this.skipRouteCheckHeaders});
  }

  public setBookingConditions(filmId: number, conditions: any) : Observable<{conditions: string}> {
    const url = `${this.urlBase}/${filmId}/booking-conditions`;
    return this.http.put<{conditions: string}>(url, conditions);
  }

  public getExtraDownloadUrl(id: number): string {
    let params = new HttpParams();

    /* if (this.authenticationService.isAuthenticated) {
      params = params.append('bearer', this.authenticationService.token);
    } */

    return `${this.urlBase}/extra/download/${id}?${params.toString()}`;
  }
}
