<div style="max-width: 1060px; margin: 0 auto;" *ngIf="film">
  <article class="project-item-wrapper">
    <header class="project-header">
      <a class="project-title" [routerLink]=" ['/', 'film', film.slug] " [title]="film.internationalTitle">
        <h1> {{ film.internationalTitle }} </h1>
      </a>

      <h3 class="project-director" *ngIf="film.director"> {{ 'fspro.front.filmcard.label.directed_by' |  translate }} {{film.director}}</h3>

      <div style="display:flex;">
        <h4 class="project-country-language"> {{ film.countries}} </h4>
        <h4 class="project-country-language"> {{ film.languages.join(', ') }}</h4>
      </div>

      <div class="meta-list">
        <div class="meta-block" *ngFor="let contact of film?.contacts">
          <div class="meta-cat">{{ 'fspro.front.filmcard.label.' + contact.company.type |  translate }}</div>
          <div class="meta-title"> {{ contact?.company?.name }}</div>
          <button *ngIf="contact.email !== null && contact.email !== ''" class="btn-send-mail" (click)="openSendMail(contact)">{{contact?.name}}</button>
          <div *ngIf="contact.email === null || contact.email === ''" class="contact-name" >{{contact?.name}}</div>
        </div>
      </div>


      <div class="actions-container">
        <ul class="actions"
          [ngClass]="{'has-actions': film.availabilityWrapper?.canWatchFilm || (!film.availabilityWrapper?.isExpired && film.availabilityWrapper?.missScreeningRequest && film.availabilityWrapper?.hasMembership) || film.trailerUrl !== null || (film.availabilityWrapper?.isLoggedIn && film.availabilityWrapper?.canWatchFilm)}">
          <li>
            <button class="btn-more-info" [ngClass]="isShowMoreInfo ? 'open' : ''"
                    (click)="isShowMoreInfo = !isShowMoreInfo">{{ 'fspro.front.filmcard.label.more_info' |  translate
              }}</button>
          </li>
          <li>
            <a mat-flat-button *ngIf="film.availabilityWrapper?.canWatchFilm" [routerLink]=" ['/', 'film', film.slug , 'action','autoPlay'] ">
              <mat-icon svgIcon="play_arrow"></mat-icon> {{ film.movieMediumTxtBtnPlay? film.movieMediumTxtBtnPlay :  'fspro.front.filmcard.label.play_film' |  translate }}
            </a>
            <a mat-flat-button
              *ngIf="!film.availabilityWrapper?.isExpired && film.availabilityWrapper?.missScreeningRequest && film.availabilityWrapper?.hasMembership"
              [routerLink]=" ['/', 'film', film.slug] ">
              <mat-icon svgIcon="play_arrow"></mat-icon> {{ film.movieMediumTxtBtnPlay? film.movieMediumTxtBtnPlay :  'fspro.front.filmcard.label.play_film' |  translate }}
            </a>
          </li>
          <li *ngIf="film.trailerUrl !== null">
            <a mat-flat-button [routerLink]=" ['/', 'film', film.slug, 'action','trailer'] ">
              <mat-icon svgIcon="play_arrow_outline"></mat-icon> {{ film.trailerMediumTxtBtnPlay? film.trailerMediumTxtBtnPlay : 'fspro.front.film.filmsummary.button.trailer' |  translate }}
            </a>
          </li>


          <li
            *ngIf="!film.inWatchList && film.availabilityWrapper?.isLoggedIn && film.availabilityWrapper?.canWatchFilm">
            <button mat-flat-button (click)="addToWatchList()">
              <mat-icon svgIcon="watch_later"></mat-icon> {{ 'fspro.front.film.show.button.later' |  translate }}
            </button>
          </li>


          <li
            *ngIf="film.inWatchList && film.availabilityWrapper?.isLoggedIn && film.availabilityWrapper?.canWatchFilm">
            <button mat-flat-button (click)="removeFromWatchList()">
              <mat-icon svgIcon="remove_circle_outline"></mat-icon> {{ 'fspro.front.filmcard.label.remove' |  translate }}
            </button>
          </li>
        </ul>
      </div>
    </header>

    <section class="more-info-block" [ngClass]="isShowMoreInfo ? 'open' : ''">
      <ng-container>
        <header class="section-header" *ngIf="film.synopsis">
          <h1>{{ 'fspro.front.filmcard.label.synopsis' |  translate }}</h1>
        </header>
        <div class="more-desc">
          <p markdown >{{ film.synopsis}} </p>
        </div>
      </ng-container>
      <ng-container *ngIf="film.directorQuote">
        <header class="section-header">
          <h1 [innerHTML]="'fspro.front.film.filmdirectorstatement.label.title' |  translate | safeHtml"></h1>
        </header>
        <div class="more-desc">
          <p markdown >{{ film.directorQuote}} </p>
        </div>
      </ng-container>
      <ng-container *ngIf="film.directorBio">
        <header class="section-header">
          <h1>{{ 'fspro.front.filmcard.label.label_biography' |  translate }}</h1>
        </header>
        <div class="more-desc">
          <p *ngFor="let bio of film.directorBio.split('|')" [innerHTML]="bio"></p>
        </div>
      </ng-container>
    </section>

    <section class="work-list-block">
      <div class="work-list-wrapper">
        <div class="section-header" *ngIf="displayPreworkTitle">
          <h1> {{ 'fspro.front.filmcard.label.previous_work' |  translate }} </h1>
        </div>
        <fspro-film-grid [provider]="previousWorkProvider" (hasData)="displayPreworkTitle = $event" [isCoproduction]="true"></fspro-film-grid>

      </div>

    </section>
  </article>
</div>
