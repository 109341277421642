export enum RumoInteraction {
  CLICK = 'click',
  PURCHASE = 'purchase',
  BOOKMARK = 'bookmark',
  LIKE = 'like',
  DISLIKE = 'dislike',
  MORE_INFO = 'moreInfo',
  START = 'start',
  COMPLETE = 'complete',
  ADD_TO_LIST = 'addToList',
  SHARE = 'share'
}

export interface IRumoInteractionDto {
  user_id: string;
  movie_id: string;
  interaction_type: RumoInteraction;
}
